import {Needlework} from "domain/Needlework";
import {Col, Row, Image, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import React from "react";


export function NeedleworkCard(props: {
    item: Needlework,
}) {
    return (
        <Card>
            <Card.Body>

                <Row>
                    <Col xs={6} md={4}>
                        <Image className="w-100" src={props.item.thumbnail} thumbnail  />
                    </Col>
                    <Col xs={6} md={8}>
                        <Card.Title>
                            <Link to={"/vzor/" + props.item.id}>{props.item.title}</Link>
                        </Card.Title>
                        <Card.Text>
                            Líbí se {props.item.likes} lidem
                            <br/>
                            Zobrazeno: {props.item.views}x
                            <br/>
                            Datum přidání: {props.item.creationTime.toLocaleDateString()}
                        </Card.Text>
                        <Card.Link href="#">Líbí se mi</Card.Link>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}