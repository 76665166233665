import {Col, Row} from "react-bootstrap";
import {Client, Order} from "api/Client";
import {useEffect, useState} from "react";
import Loading from "components/Loading";
import {Needlework} from "domain/Needlework";
import {NeedleworkCard} from "components/NeedleworkCard";
import {BASE_URL} from "config";


export default function List(props: {
    order?: Order,
}) {
    const client = new Client(BASE_URL);
    const [data, setData] = useState<Needlework[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();

    useEffect(() => {
        setLoading(true);
        client.fetchNeedleworks(props.order).then(setData).catch((e) => setError(e.toString())).finally(() => setLoading(false));
    }, [props.order]);

    if (loading) {
        return (
            <Loading/>
        );
    }
    if (error !== undefined) {
        return (
            <h4 className="m-5 text-center">{error}</h4>
        );
    }
    const items = (data ?? []).map((i) => (
        <Col lg={6} md={12} xl={4} className="p-2" key={i.id}>
            <NeedleworkCard item={i}/>
        </Col>
    ))
    return (
        <Row className="p-2">
            {items}
        </Row>
    )
}