import {NeedleworkApi} from "api/domain/NeedleworkApi";


export class Needlework {
    id: string;
    title: string;
    thumbnail: string;
    likes: number;
    views: number;
    creationTime: Date;

    constructor(from: NeedleworkApi) {
        this.id = from.id;
        this.title = from.title;
        this.thumbnail = from.thumbnail;
        this.likes = from.likes;
        this.views = from.views;
        this.creationTime = new Date(from.creation_time);
    }
}