import {Needlework} from "domain/Needlework";
import {NeedleworkApi} from "api/domain/NeedleworkApi";

export type Order = "time" | "likes" | "views";

export class Client {
    baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    fetchNeedleworks(order?: Order): Promise<Needlework[]> {
        let url = this.baseUrl + "/needlework";
        if (order !== undefined)
            url += "?order=" + order;
        return fetch(url)
            .then(res => res.json())
            .then(res => res.map((i: NeedleworkApi) => new Needlework(i)))
    };

    fetchNeedlework(id: string): Promise<Needlework> {
        let url = this.baseUrl + "/needlework/" + id;
        return fetch(url)
            .then(res => res.json())
            .then(res => new Needlework(res))
    };
}