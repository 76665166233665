import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Router, Link, Route, Switch} from "react-router-dom";
import { createBrowserHistory } from 'history';
import List from "pages/List";
import Detail from "pages/Detail";

const history = createBrowserHistory();

export default function App() {
    return (
        <Router history={history}>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Link className="navbar-brand" to="/">iVyšívání</Link>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Link className="nav-link" to="/nejlepe-hodnocene">Nelépe hodnocené</Link>
                        <Link className="nav-link" to="/nejprohlieznejsi">Nejprohlíženejší</Link>
                        <Link className="nav-link" to="/nejnovejsi">Nejnovější</Link>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Link className="nav-link" to="/vytvorit">Vytvořit vlastní</Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Container fluid>
                <Switch>
                    <Route path="/nejlepe-hodnocene">
                        <List order="likes"/>
                    </Route>
                    <Route path="/nejprohlieznejsi">
                        <List order="views"/>
                    </Route>
                    <Route path="/nejnovejsi">
                        <List order="time"/>
                    </Route>
                    <Route path="/vytvorit">

                    </Route>
                    <Route path="/vzor/:needlesworkId">
                        <Detail/>
                    </Route>
                    <Route path="/">
                        <List/>
                    </Route>
                </Switch>
            </Container>
        </Router>
    );
};
