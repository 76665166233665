import {Col, Row} from "react-bootstrap";
import {Client} from "api/Client";
import React, {useEffect, useState} from "react";
import Loading from "components/Loading";
import {Needlework} from "domain/Needlework";
import {useParams} from "react-router-dom";
import {BASE_URL} from "config";
import {NeedleworkEditor} from 'needlework-editor';
import 'needlework-editor/dist/index.css';


export default function Detail() {
    const {needlesworkId} = useParams<{ needlesworkId: string }>();

    const client = new Client(BASE_URL);
    const [data, setData] = useState<Needlework>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();

    useEffect(() => {
        setLoading(true);
        client.fetchNeedlework(needlesworkId).then(setData).catch((e) => setError(e.toString())).finally(() => setLoading(false));
    }, [needlesworkId]);

    let sidePanel = <Loading/>;
    if (loading) {
        // Do nothing
    } else if (error !== undefined) {
        sidePanel = (
            <h4 className="m-5 text-center">{error}</h4>
        );
    } else if (data === undefined) {
        sidePanel = (
            <h4 className="m-5 text-center">Položka nenalezena</h4>
        );
    } else {
        sidePanel = (
            <>
                <h4>{data.title}</h4>
                <p>
                    Líbí se {data.likes} lidem
                    <br/>
                    Zobrazeno: {data.views}x
                    <br/>
                    Datum přidání: {data.creationTime.toLocaleDateString()}
                </p>
            </>
        );
    }
    return (
        <Row className="min-vh-100">
            <Col>
                <div className="d-flex flex-column h-100">
                    <div className="row justify-content-center bg-blue flex-grow-1">
                        <NeedleworkEditor baseUrl={BASE_URL} id={needlesworkId}/>
                    </div>
                </div>
            </Col>
            <Col xl={2} lg={3} md={4} className="pt-3">
                {sidePanel}
            </Col>
        </Row>
    )
}